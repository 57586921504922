import React from 'react'
import { InvoicesTable } from '../components/invoicesTable'

import CardInfoComponent from '../components/cardInfoComponent'
import {useEffect,useState} from 'react'
import axios from 'axios'
import { FaUsers, FaUsersRectangle } from "react-icons/fa6";

import { LoadingCard } from '../components/loadingCard';
import { buildLink, formatNumber } from '../functions'

const CashesPage = () => {




  const [loading,SetLoading] = useState(true)
  const [items,setItems] =  useState(null);

  useEffect(() => {
    async function getItems(){
      SetLoading(true)
     await axios.get(buildLink('invoices/sumData','')).then((res)=>{
      SetLoading(false)
      setItems(res.data)
    })
    }
  getItems();
  }, [])









  return (
    <>
    
    
    { loading?<div className='px-3 grid-cols-1 pb-10 pt-2 grid md:grid-cols-2 gap-3 w-full    h-fit'>
    <LoadingCard/>
    <LoadingCard/>
    </div>:
      <div className='      px-3 grid-cols-1 pb-10 pt-2 grid md:grid-cols-2 gap-3 w-full    h-fit  '>
       
<CardInfoComponent

title={"Purshases"}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<FaUsers />} values={[formatNumber(items.sumCost.sumLL)+"LL",formatNumber(items.sumCost.sumdolar)+"$"]} />




<CardInfoComponent

title={"Sales"}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<FaUsersRectangle />} values={[formatNumber(items.sumRevenu.sumLL)+"LL",formatNumber(items.sumRevenu.sumdolar)+"$"]} />

</div>


}
    

<div className='px-3'>
<InvoicesTable/>
</div>












    </>
  )
}

export default CashesPage