export const AccountReducer = (state, action) => {
    switch (action.type) {
        case "setLoged":
            return {
                ...state,
                loged: action.payload
            }

            case "setUsername":
                return {
                    ...state,
                    username: action.payload
                }
                case "setLodingLogin":
                    return {
                        ...state,
                        loadingLogin: action.payload
                    }
    
                
        
    

        default:
            return state
    }
}



export const initialState = {
    loged:false,
    username:null,
    loadingLogin:false

}