import React, { useEffect, useState } from 'react'
import logo from '../assets/images/logo.png'
const LoadingLoginImag = () => {
  const [dots, setDots] = useState('');
  let flip = false;



  useEffect(() => {
      const interval = setInterval(() => {
          setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
          flip =!flip;
          if(flip){
            document.getElementById("imageRotate").classList.add("rotated") 
            document.getElementById("imageRotate").classList.remove("notretated") 
          }else{
            document.getElementById("imageRotate").classList.remove("rotated") 
            document.getElementById("imageRotate").classList.add("notretated") 
          }
      }, 1000);

      return () => clearInterval(interval);
  }, []);
  return (
    <div className=' top-0 left-0  fixed w-screen  h-screen z-50 bg-white'>
      <div className=' flex justify-center text-center  w-full h-full'>
        <div className=' my-auto  h-28 w-28'>
      <img id='imageRotate' className={`  transition-all ease-linear duration-500 animate-pulse w-full h-full `} src={logo}/>
      </div>
      </div>
      <div className=' absolute inset-0 w-full h-full flex  top-[70%] justify-center text-center'>
        <h1 className='text-xl text-gray-600 flex justify-center gap-1  text-center'><span>Loading</span><span>{dots}</span></h1>
      </div>
    </div>
  )
}

export default LoadingLoginImag