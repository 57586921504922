import { Typography } from "@material-tailwind/react";
 
export function LoadingCard() {
  return (
    <div className="max-w-full animate-pulse  px-6 hover:shadow-lg transition-all py-10 flex flex-col  justify-between flex-grow w-full h-60 shadow-md border rounded-md bg-white ">
      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[50%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      <Typography
        as="div"
        variant="paragraph"
        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      <Typography
        as="div"
        variant="paragraph"
        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      <Typography
        as="div"
        variant="paragraph"
        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      <Typography
        as="div"
        variant="paragraph"
        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
    </div>
  );
}