import { Typography } from "@material-tailwind/react";
 
export function LoadingTable() {
  return (
    <div className=" animate-pulse  px-6 hover:shadow-lg transition-all py-10 flex flex-col  justify-between flex-grow w-full h-60 shadow-md border rounded-md bg-white ">
     <div className=" w-full  flex flex-row gap-2">
      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      </div>


      <div className=" w-full  flex flex-row gap-2">
      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      </div>


      <div className=" w-full  flex flex-row gap-2">
      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      </div>

      <div className=" w-full  flex flex-row gap-2">
      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>

      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-[80%] rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      </div>
     
    </div>
  );
}