import React from 'react'
import CardInfoComponent from '../components/cardInfoComponent'
import {useEffect,useState} from 'react'
import axios from 'axios'
import { FaArrowTrendDown, FaArrowTrendUp, FaEye, FaUser, FaUserGear, FaUsers, FaUsersRectangle } from "react-icons/fa6";
import { CustomersTable } from '../components/customersTable';
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import { LoadingCard } from '../components/loadingCard';
import { buildLink, formatNumber } from '../functions';

const ClientSuppliersPage = () => {

  const [loading,SetLoading] = useState(true)
  const [items,setItems] =  useState(null);
  const [activeItems,setActiveItems] = useState(null);
  const [typeOpen,setTypeOpen] = useState('');

  const [size, setSize] = React.useState(null);
  const handleOpen = (value) => setSize(value);
  useEffect(() => {
    async function getItems(){
      SetLoading(true)
     await axios.get(buildLink('customers/topCustomers','')).then((res)=>{
      SetLoading(false)
      setItems(res.data)
    })
    }
  getItems();
  }, [])

function openActiveItmesFilter(type){
  if(type == 'customer'){
    setTypeOpen("Customer")
    setActiveItems(items.topCustomers);
  }else{
    setTypeOpen("Supplier")
    setActiveItems(items.topSupplier);
  }

}








  return (
    <>
      { loading?<div className='px-3 grid-cols-1 pb-10 pt-2 grid md:grid-cols-2 gap-3 w-full    h-fit'>
    <LoadingCard/>
    <LoadingCard/>
    </div>:
      <div className='      px-3 grid-cols-1 pb-10 pt-2 grid md:grid-cols-2 gap-3 w-full    h-fit  '>
       
<CardInfoComponent
onclick={()=>{
  openActiveItmesFilter("customer");
  handleOpen("lg")

}}
title={"Top Clients"}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<FaUsers />} values={[<FaEye/>]} />




<CardInfoComponent
onclick={()=>{
  openActiveItmesFilter("suuplier");
  handleOpen("lg")

}}
title={"Top Suppliers"}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<FaUsersRectangle />} values={[<FaEye/>]} />

</div>


}

{/* </div> */}
{/* </div> */}



<div className='px-3'>
<CustomersTable/>
</div>









<Dialog
        open={
          size === "xs" ||
          size === "sm" ||
          size === "md" ||
          size === "lg" ||
          size === "xl" ||
          size === "xxl"
        }
        size={size || "md"}
        handler={handleOpen}
      >
        <DialogHeader>Top {typeOpen} </DialogHeader>
        <DialogBody>
        <div className="overflow-x-auto shadow-md  rounded-md ">
  <table className="table-auto min-w-full divide-y divide-gray-200">
    <thead className="bg-gray-50">
      <tr className=' hover:bg-gray-200'>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Code</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ammount</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">

    {activeItems&& activeItems.map((item)=>  <tr className=' hover:bg-gray-200'>
        <td className="px-6 py-4 whitespace-nowrap">{item.acc_code}</td>
        <td className="px-6 py-4 whitespace-nowrap">{item.description}</td>
        <td className="px-6 py-4 whitespace-nowrap">{item.email}</td>
        <td className="px-6 py-4 whitespace-nowrap text-red-500 font-bold">{formatNumber(item.amount)}</td>
      </tr>
    )}
    
 
    </tbody>
  </table>
</div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => handleOpen(null)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => handleOpen(null)}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>

    </>
  )
}

export default ClientSuppliersPage