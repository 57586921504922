import Cookies from "js-cookie";

// function formatNumber(number) {
//     // Convert the number to a string and split it into integer and fractional parts
//     let [integerPart, fractionalPart] = number.toString().split('.');

//     // Handle edge cases where integer part is empty or zero
//     integerPart = integerPart || '0';

//     // Ensure fractional part has exactly two digits
//     if (fractionalPart) {
//         fractionalPart = fractionalPart.substring(0, 2).padEnd(2, '0');
//     }

//     // Insert commas at the desired positions in the integer part
//     let formattedIntegerPart = '';
//     if (integerPart.length > 0) {
//         if (integerPart.length <= 3) {
//             formattedIntegerPart = integerPart;
//         } else {
//             formattedIntegerPart = integerPart.slice(0, 3) + ',' + integerPart.slice(3, 6);
//             if (integerPart.length > 6) {
//                 formattedIntegerPart += ',' + integerPart.slice(6);
//             }
//         }
//     }

//     // Combine the formatted integer part and the fractional part
//     let formattedNumber = formattedIntegerPart;
//     if (fractionalPart) {
//         formattedNumber += '.' + fractionalPart;
//     }

//     return formattedNumber;
// }

const formatNumber = (num) => {
  const formatNumber = parseFloat(num).toFixed(2);
  return formatNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function getDbName() {
  const db_name = Cookies.get("db_name"); // Use Cookies.get() to retrieve the value
  return db_name ? db_name : "";
}

function buildLink(endpoints, params) {
  const parameter = params ? params : "";
  return (
    "https://test.softpro.me/laravel/public/api/" +
    endpoints +
    "?db_name=" +
    getDbName() +
    parameter
  );
}

export { formatNumber, buildLink };
