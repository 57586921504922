import React, { useState ,useEffect} from 'react'
import CardInfoComponent from '../components/cardInfoComponent'
import { FaArrowTrendDown, FaArrowTrendUp, FaEye } from "react-icons/fa6";
import axios  from 'axios';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
  } from "@material-tailwind/react";
import { ItemsTable } from '../components/itemsTable';
import { LoadingCard } from '../components/loadingCard';
import { buildLink } from '../functions';

const ItemsPage = () => {
  const [loading,SetLoading] = useState(true)
  const [items,setItems] =  useState(null);
  const [activeItems,setActiveItems] = useState(null);
  const [topmin,Settopmin] = useState('');

  useEffect(() => {
    async function getItems(){
      SetLoading(true)
     await axios.get(buildLink('items/minmax','')).then((res)=>{
      SetLoading(false)
      setItems(res.data)
    })
    }
  getItems();
  }, [])

function openActiveItmesFilterminmax(type){
  if(type == 'top'){
    Settopmin("Top")
    setActiveItems(items.topItems);
  }else{
    Settopmin("Min")
    setActiveItems(items.minItems);
  }

}


 

    const [size, setSize] = React.useState(null);
 
    const handleOpen = (value) => setSize(value);
  return (
    <>
    { loading?<div className='px-3 grid-cols-1 pb-10 pt-2 grid md:grid-cols-2 gap-3 w-full    h-fit'>
    <LoadingCard/>
    <LoadingCard/>
    </div>:
      <div className='      px-3 grid-cols-1 pb-10 pt-2 grid md:grid-cols-2 gap-3 w-full    h-fit  '>
       
<CardInfoComponent  onclick={()=>{
  openActiveItmesFilterminmax("top");
  handleOpen("lg")
}}
 title={"Top 15 Items"}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<FaArrowTrendUp />} values={[<FaEye/>]} />




<CardInfoComponent  onclick={()=>{
  openActiveItmesFilterminmax("min");
  handleOpen("lg")

}} title={"Min 15 Items"}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<FaArrowTrendDown />} values={[<FaEye/>]} />

</div>
}

<div className='px-3'>
<ItemsTable/>

</div>














<Dialog
        open={
          size === "xs" ||
          size === "sm" ||
          size === "md" ||
          size === "lg" ||
          size === "xl" ||
          size === "xxl"
        }
        size={size || "md"}
        handler={handleOpen}
      >
        <DialogHeader>{topmin} 15 Items Sales</DialogHeader>
        <DialogBody>
        <div className="overflow-x-auto overflow-y-auto  h-80 shadow-md  rounded-md ">
  <table className="table-auto min-w-full divide-y divide-gray-200">
    <thead className="bg-gray-50">
      <tr className=' hover:bg-gray-200'>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Code</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Qty</th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">

    {activeItems&& activeItems.map((item)=>  <tr className=' hover:bg-gray-200'>
        <td className="px-6 py-4 whitespace-nowrap">{item.code}</td>
        <td className="px-6 py-4 whitespace-nowrap">{item.Description}</td>
        <td className="px-6 py-4 whitespace-nowrap">{item.sumQty}</td>
        <td className="px-6 py-4 whitespace-nowrap text-red-500 font-bold">{item.price}</td>
      </tr>
    )}
    
 
    </tbody>
  </table>
</div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => handleOpen(null)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => handleOpen(null)}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default ItemsPage