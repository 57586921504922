
import { useEffect, useRef, useState } from 'react';
import axios from 'axios'
import { buildLink, formatNumber } from "../functions.js";
import { useLocation, useNavigate } from "react-router-dom";
import {  FaSpinner, FaUser } from "react-icons/fa6";
import { LoadingTable } from "./loadingtable";
import { FaSearch, FaUserCheck } from "react-icons/fa";
import {
  Chip,
  Input,
  Tab,
  Tabs,
  TabsHeader,
  Typography, 
} from "@material-tailwind/react";
 






export function CustomersTable() {
  const location = useLocation()
  const [items, setItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getItems();
  }, [location.pathname, activeFilter])

  function getItems() {

    setItems(null)
    if (searchValue) {
      setLoadingSearch(true)
    }
    setLoading(true)
    axios.get(buildLink('customers',`&filter=${activeFilter}${searchValue ? `&search=${searchValue}` : ""}`)).then((res) => {
      setItems(res.data)
      setLoading(false)
      setLoadingSearch(false)
    })
  }






  

useEffect(()=>{
  if(searchValue == ''){
      getItems()
    }
},[searchValue])
const handleInputChange = (search) => {
  setSearchValue(search)
 
  // Call debounceSearch function instead of directly calling the API
  // getItems(search);
};




const navigate = useNavigate();



  const TABS = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Customer",
      value: "1",
    },
    {
      label: "Supplier",
      value: "0",
    },
  ];

  const TABLE_HEAD = ["","Code", "Name","Balance","Mobile","Email","City"];


  return (

 
    <div className=" container  w-full flex-col flex gap-2  h-full bg-white shadow-md rounded-md ">
     <div className=' p-5 flex flex-col w-full justify-start gap-5 md:gap-10'>
        <div className=' flex flex-col justify-start  text-left'>
          <h1 className=' font-extrabold text-xl  '>Invoice List</h1>
          <p className=' text-sm  text-gray-500'>See all invoice and invoice Info in your data</p>
        </div>
        <div className=' flex flex-col md:flex-row w-full  gap-5  justify-between'>
        <Tabs value="all" className="w-full  md:w-max">
            <TabsHeader >
              {TABS.map(({ label, value }) => (
                <Tab   onClick={()=>{
                  setActiveFilter(value);
               
                }} key={value} value={value}>
                  &nbsp;&nbsp;{label}&nbsp;&nbsp;
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
          <div className=' flex flex-row justify-end gap-1'>
          <div className="w-full md:w-72">
            <Input
            onChange={(e)=>handleInputChange(e.target.value)}
              label="Search"
             
            />
          </div>
          <button onClick={()=>getItems()}  disabled={loadingSearch} className=' rounded-md  bg-cyan-400 text-white px-4'>
            { loadingSearch?<div className=' animate-spin'><FaSpinner/></div>: <FaSearch/> }

          </button>
          </div>
        </div>
      </div>






      {loading?<LoadingTable/>:

<div className="container w-full overflow-y-auto mb-8 mt-2">
<table className="w-full min-w-max table-auto text-left">
  <thead className=' border-y border-blue-gray-100 bg-blue-gray-50/50 p-4  opacity-70 text-sm font-normal'>
  <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
              {head}
                </th>
              ))}
            </tr>
  </thead>
  <tbody>
  {items && items.map(

({ acc_code,description,email,customer,City,mobile,balance,idaccount}, index) => {
  const isLast = index === items.length - 1;
  const classes = isLast
    ? "p-4"
    : "p-4 border-b border-blue-gray-50";

  return (
    <tr className=' cursor-pointer' onClick={()=>navigate('customer?id='+idaccount+"&name="+description)} key={acc_code}>
      <td className={classes}>
     
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
             {customer==0?<FaUser/>:<FaUserCheck/>}
            </Typography>
          
      </td>
      <td className={classes}>
        <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal"
          >
            {acc_code}
          </Typography>
       
        </div>
      </td>
      <td className={classes}>
        <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal"
          >
            {description}
          </Typography>
       
        </div>
      </td>
      <td className={classes}>
      <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className=" text-red-600 font-bold"
          >
            {balance?
            formatNumber(balance):"0"}
          </Typography>
       
        </div>
      </td>
      {/* <td className={classes}>
        <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal"
          >

            {customer==0?<strong className=' text-cyan-700 '>Supplier</strong>:<strong className=' text-green-700'>Customer</strong>}
          </Typography>
       
        </div>
      </td> */}
      <td className={classes}>
        <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal"
          >
            {mobile}
          </Typography>
       
        </div>
      </td>
      <td className={classes}>
        <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className=" text-red-600 font-bold"
          >
            {email}
          </Typography>
       
        </div>
      </td>
      <td className={classes}>
      <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className=" text-red-600 font-bold"
          >
            {City}
          </Typography>
       
        </div>
      </td>


     
    </tr>
  );
},
)}
  </tbody>
</table>
</div>
}
    </div>


  );
}