export const DashboardReducer = (state, action) => {
    switch (action.type) {
        case "setOpenSidebar":
            return {
                ...state,
                openSideBar: action.payload
            }
        
        

        default:
            return state
    }
}



export const initialState = {
    openSideBar:true
}