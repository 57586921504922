import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { buildLink } from '../functions';
import Cookies from 'js-cookie';
import { AccountContext } from '../contexts/AccountContext';
import { FaSpinner } from 'react-icons/fa6';


const StartPage = () => {

    const navigate = useNavigate('/')
    const [loading,setLoading]  = useState(false)

    const [accountState,accountDispatch] = useContext(AccountContext);


    useEffect(()=>{
        // alert("ljdsfl")

        const username = Cookies.get('userName');
        const db_name = Cookies.get('db_name');
        // alert('username')
       
    //    if(!db_name){
         if(!username){
         navigate('/login')
         accountDispatch({type:"setLodingLogin",payload:false})
         }else{
           const obj = {
             username: username,
             password: Cookies.get('password'),
           }
           setLoading(true)
           accountDispatch({type:"setLodingLogin",payload:true})
           // setLoading(true)
           axios.post(buildLink('login'),obj).then((res)=>{
            accountDispatch({type:"setLodingLogin",payload:false})
             // setLoading(false)
             // console.log(res)
            
             if(res.data.success){
                setLoading(false)
               // localStorage.setItem('token',res.data.token)
               Cookies.set('userName',obj.username);
               Cookies.set('password',obj.password);
                navigate('/home',{replace:true});
                Cookies.set("db_name",res.data.db_name);
                accountDispatch({type:"setLoged",payload:true});
                accountDispatch({type:"setUsername",payload:username})
       
       
             }
             setLoading(false)
           })
         }
    //    }else{
    //      // alert("hel")
    //      accountDispatch({type:"setLoged",payload:true});
    //    }
        },[]);


  return (
    <div className=' w-screen h-screen  flex justify-center text-center'>
      {/* <img className=' w-full h-full' src={splach}/> */}
    </div>
  )
}

export default StartPage