import React from "react";

const CardInfoComponent = ({
  onclick,
  title,
  values,
  date,
  description,
  icon,
}) => {
  const colors = ["bg-red-400", "bg-green-400", "bg-blue-400", "bg-yellow-400"]; // Add more colors as needed

  return (
    <button
      onClick={() => {
        if (onclick) onclick();
      }}
      className=" xl:px-6 px-3  hover:shadow-lg transition-all py-10 flex flex-col  justify-between flex-grow w-full h-60 shadow-md border rounded-md bg-white"
    >
      <div
        className={`${
          values.length > 1 ||
          window.location.pathname.includes("cash")
            ? " flex-col gap-2 mb-2 "
            : " lg:flex-row flex-row"
        } w-full flex  justify-between`}
      >
        <div className="  flex flex-row justify-start gap-4 ">
          <div className=" text-cyan-600 text-4xl">{icon}</div>
          <h1 className=" text-cyan-600  text-3xl font-bold">{title}</h1>
        </div>
        <div className=" flex flex-row justify-start gap-1">
          {values.map((value, index) => {
            const color =
              values.length === 1
                ? "bg-cyan-400"
                : colors[index % colors.length];

            return (
              <div
                className={` lg:p-5 p-3 border text-white font-bold  lg:text-xl text-sm max-md:text-md rounded-md ${color}`}
              >
                {value}
              </div>
            );
          })}
        </div>
      </div>
      <div className=" flex flex-col gap-2">
        <p className=" text-sm  text-gray-700">{description}</p>
        <div className=" text-xs text-gray-400 flex flex-row justify-start text-end">
          {" "}
          last Update on {date}
        </div>
      </div>
    </button>
  );
};

export default CardInfoComponent;
