
import { FaListCheck, FaSpinner, FaUserGroup, FaUsersBetweenLines } from "react-icons/fa6";
import { GiReturnArrow } from "react-icons/gi";
import { GiExpense } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import CardInfoComponent from "../components/cardInfoComponent";
import { DiGitBranch } from "react-icons/di";
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { LoadingCard } from "../components/loadingCard";
import { useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { buildLink } from "../functions";
import { AccountContext } from "../contexts/AccountContext";

const Home = () => {
  const [accountState,accountDispatch] = useContext(AccountContext);
  const [countsData,setCountsData] = useState(null);
  const [loadingCounts,setLoadingCounts] = useState(false);
  const [amountsRevenu, setAmountsRenvenu] = useState(null);
  const [amountsCosts, setAmountsCosts] = useState(null);
  const [months, setMonths] = useState(null);
  const [loaded,setLoaded] = useState(false)
const history = useNavigate()
 
  

  useEffect(()=>{

    setLoadingCounts(true)
    axios.get(buildLink('dashboard','')).then((res)=>{
    setLoaded(true)
      setCountsData(res.data.countData);
      const extractedRevenuAmounts = res.data.chartData.revenuChart.map(item => item.amount);
      const extractedCostsAmounts = res.data.chartData.costChart.map(item => item.amount);
      const extractedMonths = res.data.chartData.costChart.map(item => item.month);
      setAmountsCosts(extractedCostsAmounts);
      setAmountsRenvenu(extractedRevenuAmounts);
      setMonths(extractedMonths)
setLoadingCounts(false);
    })
  
  },[])


  const uData = [4000, 3000, 15000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 5800, 3908, 4800, 3800, 4300];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];

const data = {
  labels: months,
  // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  datasets: [
      {
        label: 'Ammount Revenu',
        data: amountsRevenu,
        // you can set indiviual colors for each bar
       
        borderWidth: 1,
      }
  ]
};


  return (
    <div className="flex px-3 flex-col pt-5 gap-5 justify-center w-full">
<div className={` transition-all duration-300  ease-out ${loaded?"h-32":"h-0"} relative overflow-hidden w-full rounded-md border flex px-7 flex-row justify-between   border-green-400  bg-green-200 bg-opacity-30`}>
 <div className=" flex flex-col justify-center text-left gap-3">
 <h2 className=" text-2xl font-bold  text-green-400 ">Welcom Back {accountState.username} !</h2>
 <p className=" text-xs   text-gray-600">You can examaning on new updates transactions & search in your data in this dashboard</p>
 </div>
 <div className="  max-md:hidden my-auto">
  <DiGitBranch  className=" my-auto text-red-400 rotate-[265deg]" size={80} />
 </div>
</div> 
<h2 className=" font-bold text-2xl text-black">OverView</h2>

{ loadingCounts ? 
<div className="  h-72 w-full flex justify-center text-center">
  <FaSpinner className=" text-4xl  text-cyan-400 animate-spin my-auto"/>
</div>:
<div className="  px-2 shadow-md md:px-12 rounded-md mx-2  overflow-x-auto  flex md:flex-row flex-col  justify-between">
<div className=" w-[1000px]">
 {/* {amountsCosts && amountsRevenu && months && <div  className=" ">
<BarChart
    width={1000}
     height={300}
      series={[
        { data: amountsCosts, label: 'pv', id: 'pvId' },
        { data: amountsRevenu, label: 'uv', id: 'uvId' },
      ]}
      xAxis={[{ data: months, scaleType: 'band' }]}
    />
</div>} */}

<Bar
        data={data}
        options={{
          plugins: {
            title: {
              display: true,
              text: "See ammount of revenu each month in this year"
            },
            legend: {
              display: false
            }
          }
        }}
      />
      </div>

{/* <div  >
  <h2 className="">Show data </h2>
<PieChart
  series={[
    {
      data: [
        { id: 0, value: 10, label: 'series A' },
        { id: 1, value: 15, label: 'series B' },
        { id: 2, value: 20, label: 'series C' },
      ],
    },
  ]}
  width={400}
  height={200}
/>
</div> */}
    
    </div>
}

<h2 className=" font-bold text-2xl text-black">Dashboard</h2>





{
  loadingCounts ?   <div className='  grid-cols-1 pb-10 grid md:grid-cols-3  gap-3 w-full    h-fit  '>
    <LoadingCard/>
    <LoadingCard/>
    <LoadingCard/>
    <LoadingCard/>
    <LoadingCard/>

    </div>:
    countsData &&

    <div className='  grid-cols-1 pb-10 grid md:grid-cols-3  gap-3 w-full    h-fit  '>





<CardInfoComponent title={"Sales"}
onclick={()=>history("/cash")}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<GiReturnArrow />} values={[countsData.revenu]} />


<CardInfoComponent title={"Purshases"}
onclick={()=>history("/cash")}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<GiExpense />} values={[countsData.cost]} />

<CardInfoComponent title={"Items"}
onclick={()=>history("/items")}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<FaListCheck />} values={[countsData.itemsCount]} />

<CardInfoComponent title={"Clients"}
onclick={()=>history("/suppliers")}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<FaUserGroup />} values={[countsData.customersCount]}  />

<CardInfoComponent title={"Suppliers"}
onclick={()=>history("/suppliers")}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<FaUsersBetweenLines />} values={[countsData.suppliersCount]} />


<CardInfoComponent title={"Transaction"}
 description={"you can see report of all return in your data"}
  date={"23/2/2003"} icon={<GrTransaction className=" text-cyan-600"  size={40}/>} values={[countsData.transaction]} />


</div>
}
    </div>
  
  )
}

export default Home