import { useEffect, useRef, useState } from "react";
import axios from "axios";

import { useLocation } from "react-router-dom";
import { FaSpinner } from "react-icons/fa6";
import { LoadingTable } from "./loadingtable";
import { FaSearch } from "react-icons/fa";
import {
  Chip,
  Input,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { buildLink, formatNumber } from "../functions";
import ReactPaginate from "react-paginate";

export function ItemsTable() {
  const location = useLocation();
  const [items, setItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);

  const handlePageClick = (event) => {
    console.log(event);
    setPage(parseInt(event.selected) + 1);
  };

  useEffect(() => {
    getItems();
  }, [location.pathname, activeFilter, page]);

  function getItems() {
    setItems(null);
    if (searchValue) {
      setLoadingSearch(true);
    }
    setLoading(true);
    axios
      .get(
        buildLink(
          "items",
          `&filter=${activeFilter}${
            searchValue ? `&search=${searchValue}` : ""
          }&page=${page}&per_page=50`
        )
      )
      .then((res) => {
        setItems(res.data.data);
        setPageCount(res.data.total);
        setLoading(false);
        setLoadingSearch(false);
      });
  }

  useEffect(() => {
    if (searchValue == "") {
      getItems();
    }
  }, [searchValue]);
  const handleInputChange = (search) => {
    setSearchValue(search);

    // Call debounceSearch function instead of directly calling the API
    // getItems(search);
  };

  const TABS = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Disable",
      value: "0",
    },
    {
      label: "Enable",
      value: "1",
    },
  ];

  const TABLE_HEAD = [
    "Code",
    "Name",
    "Qantity",
    "Cost",
    "Last Cost",
    "Unit",
    "Price",
    "Status",
  ];

  return (
    <div className=" container  w-full flex-col flex gap-2  h-full bg-white shadow-md rounded-md ">
      <div className=" p-5 flex flex-col w-full justify-start gap-5 md:gap-10">
        <div className=" flex flex-col justify-start  text-left">
          <h1 className=" font-extrabold text-xl  ">Items List</h1>
          <p className=" text-sm  text-gray-500">
            See all item and itemInfo in your data
          </p>
        </div>
        <div className=" flex flex-col md:flex-row w-full  gap-5  justify-between">
          <Tabs value="all" className="w-full  md:w-max">
            <TabsHeader>
              {TABS.map(({ label, value }) => (
                <Tab
                  onClick={() => {
                    setActiveFilter(value);
                  }}
                  key={value}
                  value={value}
                >
                  &nbsp;&nbsp;{label}&nbsp;&nbsp;
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
          <div className=" flex flex-row justify-end gap-1">
            <div className="w-full md:w-72">
              <Input
                onChange={(e) => handleInputChange(e.target.value)}
                label="Search"
              />
            </div>
            <button
              onClick={() => getItems()}
              disabled={loadingSearch}
              className=" rounded-md  bg-cyan-400 text-white px-4"
            >
              {loadingSearch ? (
                <div className=" animate-spin">
                  <FaSpinner />
                </div>
              ) : (
                <FaSearch />
              )}
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <LoadingTable />
      ) : (
        <div className="container w-full overflow-y-auto mb-8 mt-2">
          <table className="w-full min-w-max table-auto text-left">
            <thead className=" border-y border-blue-gray-100 bg-blue-gray-50/50 p-4  opacity-70 text-sm font-normal">
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map(
                  (
                    {
                      itemcode,
                      description,
                      HQty,
                      cost,
                      isActive,
                      LastCost,
                      Price,
                      unitName,
                    },
                    index
                  ) => {
                    const isLast = index === items.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={itemcode}>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {itemcode}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {description}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {formatNumber(HQty)}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {formatNumber(cost)}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {formatNumber(LastCost)}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className=" font-bold"
                            >
                              {unitName}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className=" text-red-600 font-bold"
                            >
                              {formatNumber(Price)}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="w-max">
                            <Chip
                              variant="ghost"
                              size="sm"
                              value={isActive ? "enable" : "disable"}
                              color={isActive ? "green" : "blue-gray"}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
      )}
      {pageCount && (
        <ReactPaginate
          pageCount={Math.ceil(pageCount / 50)}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          previousLabel={"<"}
          nextLabel={">"}
          activeClassName={"active-pagination-category"}
          containerClassName={"pagination"}
          className={"category-pagination"}
          forcePage={Number(page) > 0 ? Number(page) - 1 : 0}
        ></ReactPaginate>
      )}
    </div>
  );
}
