import { useReducer, createContext } from "react"
import { DashboardReducer, initialState } from "../reducres/DashboardReducer"

export const DashboardContext = createContext({
    state: initialState,
    dispatch: () => null
})

export const DashboardProvider = ({ children }) => {
    const [state, dispatch] = useReducer(DashboardReducer, initialState)

    return (
        <DashboardContext.Provider value={[state, dispatch]}>
            {children}
        </DashboardContext.Provider>
    )
}