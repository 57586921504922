import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa6';
import Cookies from "js-cookie";
import { buildLink } from '../functions';
import { AccountContext } from '../contexts/AccountContext';

function Copyright(props) {

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LogInPage() {

  const [accountState,dispatch] = React.useContext(AccountContext);
  const [loading,setLoading] = React.useState(false)
  const navigate = useNavigate();






  React.useEffect(()=>{

    const username = Cookies.get('userName');
    const db_name = Cookies.get('db_name');
   
   if(db_name && username){
    dispatch({type:"setLodingLogin",payload:false})
     navigate('/home');
   }else{
    dispatch({type:"setLodingLogin",payload:false})
   }

  },[])







  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
   
    const obj = {
      username: data.get('username'),
      password: data.get('password'),
    }
    setLoading(true)
    axios.post(buildLink('login'),obj).then((res)=>{
      setLoading(false)
      console.log(res)
      if(res.data.success){
        // localStorage.setItem('token',res.data.token)
        Cookies.set('userName',obj.username);
        Cookies.set('password',obj.password);
         navigate('/home',{replace:true});
         Cookies.set("db_name",res.data.db_name);
         dispatch({type:"setLoged",payload:true});
         dispatch({type:"setUsername",payload:obj.username})


      }
    })
    // navigate('/',{replace:true});
  };

  return (
    <div className=' flex flex-col justify-center  h-screen'>
    <ThemeProvider className='' theme={defaultTheme}>
      <Grid container className=' mx-auto flex justify-center   my-auto' component="main" >
       
      
        <Grid className=' mx-auto' item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 2,

              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            md={{
              mx:4
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="User Name"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading?<FaSpinner className=' animate-spin text-2xl'/>:"Sign In"}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    </div>
  );
}