import axios from 'axios';
import React, {Fragment, useEffect,useRef, useState } from 'react'
import { FaArrowLeft, FaDownLeftAndUpRightToCenter, FaDownload, FaFileInvoice, FaFilePdf, FaPerson, FaPrint, FaSpinner, FaWarehouse } from 'react-icons/fa6';
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from 'react-router-dom';
import { buildLink, formatNumber } from '../functions';
import jsPDF from "jspdf";
import amiriFont from '../assets/fonts/Amiri-Regular.ttf'; 
import 'jspdf-autotable';
import { FileDownload } from '@mui/icons-material';
import { LoadingTable } from '../components/loadingtable';
import { FaSave, FaShare } from 'react-icons/fa';
import ReactPaginate from "react-paginate";
const CustomerDetails = () => {
    const [data,setData] = useState(null)
    const navigate = useNavigate()
    const [loading,setLoading] = useState(null)
    const [pageDialog, setPageDialog] = useState(1);
    const [customerInfo,setCustomerInfo] = useState(null);
    const [open, setOpen] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const cancelButtonRef = useRef(null);
    const [invoiceDetails, setInvoiceDetails] = useState([]);
    const url = new URL(window.location.href);

    // Use URLSearchParams to extract the query parameters
    const searchParams = new URLSearchParams(url.search);

    // Get 'id' and 'name' from the query parameters
    const id = searchParams.get('id');
    const name = searchParams.get('name');
    useEffect(()=>{
        if(id){
            setCustomerInfo({id,name});
            getAccountStat()
        }
      

    },[id])

    const handlePageClickDialog = (event) => {
      console.log(event);
      setPageDialog(parseInt(event.selected) + 1);
    };

    const openInvoiceDetails = (obj) => {
      setLoadingDialog(true);
      setInvoiceDetails(obj);
      setOpen(true);
  
      axios
        .get(
          buildLink(
            "invoices/invoiceDetails",
            `&invoice_id=${obj.invoiceID}&ref=${obj.invoiceName}&page=${pageDialog}&per_page=50`
          )
        )
        .then((res) => {
          setInvoiceDetails((prev) => ({
            ...prev,
            ...res.data,
          }));
          setLoadingDialog(false);
        });
    };
    // const data = [
    //     { id: 1, date: '2024-09-28', description: 'Payment to Vendor', amount: '-$500.00', status: 'Completed' },
    //     { id: 2, date: '2024-09-27', description: 'Invoice Payment', amount: '+$1500.00', status: 'Completed' },
    //     { id: 3, date: '2024-09-26', description: 'Refund Received', amount: '+$200.00', status: 'Completed' },
    //     { id: 4, date: '2024-09-25', description: 'Subscription Fee', amount: '-$100.00', status: 'Pending' },
    //     { id: 5, date: '2024-09-24', description: 'Salary Credit', amount: '+$2000.00', status: 'Completed' },
    //     { id: 6, date: '2024-09-23', description: 'Utility Bill Payment', amount: '-$150.00', status: 'Failed' },
    //   ];


      async function getAccountStat(){
        setLoading(true)
        const response = await axios.get(buildLink('accountstatments')+"&id="+id);
        // console.log(response.data);
        setData(response.data)
        setLoading(false)
      }


      const generatePDF =  async(type) => {
        const doc = new jsPDF();
      
        // Fetch the font as a file and convert it to a base64 string
        fetch(amiriFont)
          .then(response => response.arrayBuffer())
          .then( async(buffer) => {
            const fontBase64 = btoa(
              new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
      
            // Add the font to VFS
            doc.addFileToVFS('Amiri-Regular.ttf', fontBase64);
            doc.addFont('Amiri-Regular.ttf', 'Amiri', 'normal');
      
            // Set the font for the entire document
            doc.setFont('Amiri');
            doc.setFontSize(16);
      
            // Title
            doc.text("Account Statments", 105, 10, { align: 'center' });  // Title in Arabic (centered)
      
            // Current Date
            const currentDate = new Date().toLocaleDateString('en-US');
            doc.setFontSize(12);
            doc.text(`Date: ${currentDate}`, 200, 20, { align: 'right' });  // Date (right aligned)
      
            // Sum famt (colored red)
            doc.setTextColor(255, 0, 0); // Red color for sum
            doc.text(`Balance: ${ formatNumber(data.sum_famt)}`, 200, 30, { align: 'right' }); // Sum of famt under the date
      
            // Reset text color to default black
            doc.setTextColor(0, 0, 0);
      
            // Company Name
            doc.setFontSize(14);
            doc.text(` Soft Pro`, 14, 10);  // Company name (left aligned)
      
            // Company ID (colored gray)
            doc.setFontSize(12);
            doc.setTextColor(128, 128, 128);  // Gray color
            // doc.text(`Company ID: #${id}`, 14, 28);  // Company ID (left aligned)
      
            // // Reset text color to default black
            // doc.setTextColor(0, 0, 0);
      
            // Customer Name
            doc.text(`Customer: ${customerInfo.name}`, 14, 20);  // Customer name (left aligned)
      
            // Add some space before the table
            doc.setFontSize(16);
            doc.text("All Statements", 105, 50, { align: 'center' });  // Subtitle before the table (centered)
      
            // Table Headers
            const headers = [["Ref", "Date", "Description", "Amount"]];
      
            // Define table rows with reversed Arabic text for 'description'
            const rows = data.data.map(item => [
              item.ref,
              item.ddate,
              isArabic(item.description) ? reverseArabicText(item.description) : item.description,
              item.famt,
            ]);
      
            // Draw the table using autoTable
            doc.autoTable({
              head: headers,
              body: rows,
              startY: 60, // Start after the header and title
              styles: { font: 'Amiri' }, // Ensure Amiri font is applied
              didParseCell: function (data) {
                if (data.cell.text) {
                  // Handle Arabic text in table cells by reversing the text
                  if (isArabic(data.cell.text[0])) {
                    data.cell.text[0] = reverseArabicText(data.cell.text[0]);
                  }
                  if (data.column.index === 3) { // 'famt' is in the 4th column (index 3)
                    const famtValue = parseFloat(data.cell.text[0]);  // Get the amount as a number
                    if (famtValue < 0) {
                      // Set text color to red for negative values
                      data.cell.styles.textColor = [255, 0, 0];
                    } else if (famtValue > 0) {
                      // Set text color to green for positive values
                      data.cell.styles.textColor = [0, 128, 0];
                    }
                  }
                }
              },
            });
      
            // Save the PDF
            if(type == 'save'){
            doc.save(`${customerInfo.name}-Transaction_Report-${new Date().toLocaleDateString('en-US')}.pdf`);
        
            }else{
              const pdfBlob = doc.output('blob');
            if (navigator.share) {
              try {
                await navigator.share({
                  title: "Transaction Report",
                  text: "Here is the transaction report.",
                  files: [new File([pdfBlob], `${customerInfo.name}-Transaction_Report-${new Date().toLocaleDateString('en-US')}.pdf`, { type: "application/pdf" })],
                });
                console.log('File shared successfully!');
              } catch (error) {
                console.error('Error sharing file:', error);
              }
            }
          }
          })
          .catch(error => console.error('Error loading font:', error));
      };
      
    

      function reverseArabicText(text) {
        return text.split("").reverse().join("");
      }
      
      // Helper function to check if the text is Arabic
      function isArabic(text) {
        const arabicPattern = /[\u0600-\u06FF]/;
        return arabicPattern.test(text);
      }
      

  return (
    <div className=' flex flex-col gap-2 ' >
        
        {customerInfo &&  
        <div className=' flex bg-cyan-400 bg-opacity-35 flex-row justify-between w-full'>
         <div className=' py-3  px-4  flex flex-row justify-start gap-5'> 
        <button className='  my-auto' onClick={()=>navigate(-1)}><FaArrowLeft className=' text-xl my-auto'/> </button>
        <span className=' my-auto text-xl'> {customerInfo.name}</span>
        </div>
        
      <div className=' flex flex-row gap-2'>
        <button  onClick={()=>generatePDF('save')}  className="bg-cyan-400 flex flex-row gap-2 bg-opacity-35  hover:bg-opacity-80  hover:bg-cyan-500 text-gray-800 font-bold py-2 px-4 rounded 
         items-center">
          <FaSave className=' my-auto'/>
  <span>Pdf</span>
</button>
<button  onClick={()=>generatePDF('share')}  className="bg-cyan-400 flex flex-row gap-2 bg-opacity-35  hover:bg-opacity-80  hover:bg-cyan-500 text-gray-800 font-bold py-2 px-4 rounded 
         items-center">
          <FaShare className=' my-auto'/>
  <span>Share</span>
</button>
</div>
        </div>}
        { loading ?
    <div className=' flex flex-col'>
      <LoadingTable/>
      <LoadingTable/>
      </div>
        :
       data && <div className=' flex flex-col gap-3 '>
          <div className=' px-5 w-full flex  py-2 justify-between flex-col gap-5 md:flex-row '>
          <div className=' flex gap-2 flex-col justify-start text-left'>
             <h2 className='  text-md font-bold font-body'> Statments Info</h2> 

             {/* <h2 className=' flex  flex-row justify-start gap-4'>
             <strong>Id : </strong> <span className=' text-md font-normal text-gray-600 font-body'> #{ id}</span></h2> */}
             </div>


<h2 className='  hidden md:block'>All Statments</h2>
             
<div className=' flex gap-2 flex-col justify-start text-left'>

             <h2 className=' flex  flex-row justify-start gap-4'>
             <strong>Date : </strong> <span className=' text-md font-normal text-gray-600 font-body'> { new Date().toLocaleDateString('en-US')}</span></h2>
              <h2 className=' flex flex-row justify-start gap-3'>    <strong>Balance : </strong> <span className=' text-red-600'>{formatNumber(data.sum_famt)}</span></h2>
               
        </div>
          </div> 
            <div className="overflow-x-auto">
        <table  className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
            <th className="px-4 py-2 border">Ref</th>
            <th className="px-4 py-2 border">Description</th>
              <th className="px-4 py-2 border">Date</th>
              <th className="px-4 py-2 border">Amount</th>
            </tr>
          </thead>
          <tbody>
            { data && data.data.map((item) => (
              <tr  key={item.id} className={` ${item.IDOP?"hover:bg-green-300 bg-green-500":"hover:bg-cyan-300 "}   bg-opacity-20 transition-all text-center border-t`}>
                   <td onClick={()=>{
                    if(item.IDOP){
                    
                        openInvoiceDetails({
                          invoiceID: item.IDOP,
                          // ref: item.ref,
                          // warehouse_name: item.warehouse_description,
                          customerName: customerInfo.name,
                          invoiceName: item.ref,
                          date_added: item.ddate,
                        })
                      
                    }
                   }} className={`px-2 text-xs text-gray-600 py-2 border `}>
                  {item.ref}
                </td>
                <td className="px-4 text-sm py-2 border">{item.description}</td>
                <td className="px-4 text-nowrap text-xs text-gray-600 py-2 border">{item.ddate}</td>
          
                <td className={`px-4 py-2 text-sm border ${item.famt.startsWith('-') ? 'text-red-500' : 'text-green-500'}`}>
                  { formatNumber( item.famt)}
                </td>
             
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        </div>
        }













      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:w-[70%]  w-[95%]">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex md:flex-row flex-col">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <FaFileInvoice
                          className="h-6 w-6 text-[rgb(0,172,193)]"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 border-b w-full pb-5"
                        >
                          <div className="flex flex-col gap-2">
                            <p className="text-2xl">Invoice Details</p>
                            <div className="flex flex-row  w-full gap-5 justify-center lg:justify-between ">
                              <span className="text-sm text-gray-800">
                                {invoiceDetails.invoiceName}
                              </span>

                              <span className=" text-gray-600 md:text-sm text-xs font-thin my-auto">
                                Date Added: {invoiceDetails.date_added}
                              </span>
                            </div>
                            <h2 className="text-sm text-gray-800 flex flex-row lg:justify-start justify-center gap-2">
                              <FaWarehouse className="my-auto text-gray-600" />

                              <span>{invoiceDetails.warehouse_name}</span>
                            </h2>
                            <h2 className="text-sm text-gray-800 flex flex-row lg:justify-start justify-center gap-2">
                              <FaPerson className="my-auto text-gray-600" />
                              <span>{invoiceDetails.customerName}</span>
                            </h2>
                          </div>
                        </Dialog.Title>
                        <div className="flex flex-col ">
                          <h2 className="text-2xl font-bold mt-4">
                            Invoice Items
                          </h2>
                          {loadingDialog ? (
                            <div className=" flex justify-center my-4 text-center">
                              <FaSpinner className=" animate-spin text-2xl" />
                            </div>
                          ) : (
                            <div className=" mt-4  h-[300px] overflow-y-auto ">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Item
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Unit
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Price
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Quantity
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Total
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {invoiceDetails.data &&
                                    invoiceDetails.data.map((item) => (
                                      <tr key={item.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                          {item.item_name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                          {item.unit_name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                          {formatNumber(item.Price)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                          {parseInt(item.qty)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-red-900">
                                          {formatNumber(item.Nett)}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {invoiceDetails.last_page &&
                            invoiceDetails.last_page > 1 && (
                              <ReactPaginate
                                pageCount={Math.ceil(invoiceDetails.total / 50)}
                                onPageChange={handlePageClickDialog}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                previousLabel={"<"}
                                nextLabel={">"}
                                activeClassName={"active-pagination-category"}
                                containerClassName={"pagination"}
                                className={"category-pagination"}
                                forcePage={
                                  Number(pageDialog) > 0
                                    ? Number(pageDialog) - 1
                                    : 0
                                }
                              ></ReactPaginate>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
        </div>
  )
}

export default CustomerDetails