import  { useContext, useEffect } from 'react'
import { BsArrowRightShort } from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'
import { MdDashboard } from "react-icons/md";
import { FaCashRegister, FaListCheck } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { DashboardContext } from '../contexts/DashboardContext';
import useDeviceSize from '../services/usedevicewidth';

const SideBar = () => {
    const location = useLocation()
    const[state,dispatch] = useContext(DashboardContext);
    const [width] = useDeviceSize();
    useEffect(()=>{
      // alert(width)
      if(width){
if( width>600){

  dispatch({type:"setOpenSidebar",payload:true})
}else if(width<600){

  dispatch({type:"setOpenSidebar",payload:false})
}
      }
    },[location.pathname,width])
  return (
    <>
   {state.openSideBar && <div onClick={()=>dispatch({type:"setOpenSidebar",payload:!state.openSideBar})} className=' md:hidden fixed w-full h-full bg-black  opacity-30 z-50'></div>}
    <div  className={`${state.openSideBar?"min-w-72 max-md:left-0 ":"md:min-w-20  max-md:min-w-72 max-md:-left-[100%] "} max-md:absolute max-md:left-0 max-md:top-0  bg-white h-full z-50  overflow-hidden shadow-md duration-500  transition-all pt-5 gap-5 flex flex-col`}>
        <Link to={'/home'} className={`  ${state.openSideBar?"w-[80%]":"w-[95%]"} px-4  py-4 rounded-r-full  whitespace-nowrap relative overflow-hidden group transition-all ${location.pathname=== '/home'?" text-cyan-700 bg-cyan-600 border border-cyan-700 w-[90%] ":"text-black bg-gray-200"} flex flex-row gap-1  justify-between bg-opacity-35`}>
         <div className=' flex flex-row justify-start gap-2'>
            <MdDashboard className=' my-auto text-cyan-700 text-xl'/>
           { state.openSideBar&& <span className=' text-sm font-normal '> Dashboard</span>}
            </div> 
           <BsArrowRightShort className=' text-xl group-hover:translate-x-2 transition-all  my-auto'/>
        </Link>

        <Link to={'/items'}  className={` ${state.openSideBar?"w-[80%]":"w-[95%]"} px-4  py-4 rounded-r-full  whitespace-nowrap relative overflow-hidden group transition-all ${location.pathname.includes('/items')?" text-cyan-700 bg-cyan-600 border border-cyan-700 w-[90%] ":"text-black bg-gray-200"} flex flex-row  justify-between bg-opacity-35`}> 
        <div className=' flex flex-row justify-start gap-2'>
            <FaListCheck className=' my-auto text-cyan-700 text-xl'/>
            { state.openSideBar&&  <span className=' text-sm font-normal '> Items</span>}
            </div> 
           <BsArrowRightShort className=' text-xl  group-hover:translate-x-2 transition-all  my-auto'/>
        </Link>

        <Link to={'/suppliers'}  className={` ${state.openSideBar?"w-[80%]":"w-[95%]"} px-4  py-4 rounded-r-full  whitespace-nowrap relative overflow-hidden group transition-all ${location.pathname.includes('/suppliers')?" text-cyan-700 bg-cyan-600 border border-cyan-700 w-[90%] ":"text-black bg-gray-200"} flex flex-row  justify-between bg-opacity-35`}>     
        <div className=' flex flex-row justify-start gap-2'>
            <FaUsers className=' my-auto text-cyan-700 text-xl'/>
            { state.openSideBar&&  <span className=' text-sm font-normal '> Clients & Suppliers</span>}
            </div> 
           <BsArrowRightShort className=' text-xl  group-hover:translate-x-2 transition-all  my-auto'/>
        </Link>

        <Link to={'/cash'}  className={` ${state.openSideBar?"w-[80%]":"w-[95%]"} px-4  py-4 rounded-r-full  whitespace-nowrap relative overflow-hidden group transition-all ${location.pathname.includes('/cash')?" text-cyan-700 bg-cyan-600 border border-cyan-700 w-[90%] ":"text-black bg-gray-200"} flex flex-row  justify-between bg-opacity-35`}>  
        <div className=' flex flex-row justify-start gap-2'>
            <FaCashRegister className=' my-auto text-cyan-700 text-xl'/>
            { state.openSideBar&&  <span className=' text-sm font-normal '> Cash</span>}
            </div> 
           <BsArrowRightShort className=' text-xl  group-hover:translate-x-2 transition-all  my-auto'/>
        </Link>
    </div>
    </>
  )
}

export default SideBar