import React, { useContext, useEffect } from "react";
import { BsBell, BsPersonDown, BsSearch } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { DashboardContext } from "../contexts/DashboardContext";
import { Badge, IconButton, Avatar } from "@material-tailwind/react";
import { LuRefreshCcw } from "react-icons/lu";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { AccountContext } from "../contexts/AccountContext";
import axios from "axios";
import { buildLink } from "../functions";
import { FaSpinner } from "react-icons/fa6";

const TopHeader = () => {
  const navigate = useNavigate();
  const [accountstate, accountdispatch] = useContext(AccountContext);

  function logout() {
    Cookies.remove("userName");
    Cookies.remove("db_name");
    accountdispatch({ type: "setLoged", payload: false });
    navigate("/login");
  }

  const [state, dispatch] = useContext(DashboardContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleClearSiteData = () => {
    serviceWorkerRegistration.unregister();
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
          window.location.reload();
        });
      });
    }
  };



  useEffect(() => {
    // alert('username')
    if (!accountstate.loged) {
      const username = Cookies.get("userName");
      const db_name = Cookies.get("db_name");

      //  if(!db_name){
      //   accountdispatch({type:"setLoged",payload:false});
      if (!username) {
        accountdispatch({ type: "setLodingLogin", payload: false });
        navigate("/login");
      } else {
        const obj = {
          username: username,
          password: Cookies.get("password"),
        };
        // setLoading(true)
        accountdispatch({ type: "setLodingLogin", payload: true });
        axios.post(buildLink("login"), obj).then((res) => {
          accountdispatch({ type: "setLodingLogin", payload: false });
          // setLoading(false)
          // console.log(res)
          if (res.data.success) {
            // localStorage.setItem('token',res.data.token)
            Cookies.set("userName", obj.username);
            Cookies.set("password", obj.password);
            //  navigate('/home',{replace:true});
            Cookies.set("db_name", res.data.db_name);
            accountdispatch({ type: "setLoged", payload: true });
            accountdispatch({ type: "setUsername", payload: username });
            console.log(res.data);
          }
        });
      }
    }
    //  }else{
    //   //  alert("hel")

    //    accountdispatch({type:"setLoged",payload:true});
    //    navigate('/home')
    //  }
  }, []);

  return (
    <div
      id="headercolor"
      className="  w-full  flex flex-row justify-between md:px-10  md:h-20  h-16 px-4  border "
    >
      <div className=" flex flex-row justify-start gap-3">
        <button
          onClick={() =>
            dispatch({ type: "setOpenSidebar", payload: !state.openSideBar })
          }
        >
          <div className=" group w-6 flex flex-col gap-[0.30rem] justify-center">
            <span className=" w-[80%]  h-[0.18rem]  rounded-md transition-all group-hover:w-full duration-300 ease-in-out bg-black"></span>
            <span className=" w-[59%] h-[0.17rem] rounded-md transition-all group-hover:w-full duration-300 ease-in-out bg-black"></span>
            <span className=" w-[100%] h-[0.17rem] rounded-md transition-all group-hover:w-full duration-300 ease-in-out bg-black"></span>
          </div>
        </button>
      </div>
      <div className=" w-[50%]  max-md:hidden relative my-auto">
        <input
          placeholder=" search"
          className={`text-sm outline-cyan-600 bg-gray-100 w-full rounded-md py-2 px-3`}
        ></input>
        <div className=" absolute right-5 top-2 text-gray-500">
          <BsSearch />
        </div>
      </div>
      <div className=" my-auto text-lg flex flex-row justify-end md:gap-6 gap-2">
        <button className="  h-9 w-9 rounded-full hover:bg-gray-100 transition-all" onClick={handleClearSiteData}>
          <LuRefreshCcw className=" mx-auto" />
        </button>
        <button className="  h-9 w-9 rounded-full hover:bg-gray-100 transition-all">
          <BsBell className=" mx-auto" />
        </button>
        <button onClick={handleClick}>
          <Badge overlap="circular" placement="bottom-end">
            {/* <Avatar
        size='sm'
          src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1480&amp;q=80"
          alt="profile picture"
        /> */}
            <div className=" w-9  uppercase h-9 flex justify-center text-center bg-orange-300 rounded-full">
              <h2 className=" my-auto">
                {" "}
                {!accountstate.loadingLogin ? (
                  accountstate.username && accountstate.username.charAt(0)
                ) : (
                  <FaSpinner className=" animate-spin" />
                )}
              </h2>
            </div>
          </Badge>
        </button>
      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            logout();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TopHeader;
